import { createApp } from 'vue';
import App from '/src/App.vue';
import API from '/src/api';
import router from '/src/routes/router';
import './index.css';
import '@mdi/font/css/materialdesignicons.css';

// Vuetify
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
});

createApp(App)
    .mixin({
        created() {
            this.$api = API;
        }
    })
    .use(router)
    .use(vuetify)
    .mount('#app');
