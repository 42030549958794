<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style lang="scss">
@forward 'vuetify/settings' with (
  $utilities: false,
);
</style>
