<template>
  <section class="section home-hero">
    <div class="container-medium-963px home-hero">
      <br>
      <a href="https://tracx.events/" target="_blank">
        <img src="/images/logos/tracx-logo-white.svg" alt="TRACX Logo" class="home-hero-logo">
      </a>
      <h1 class="title text-color-neutral-100 home-hero"><span class="font-italic">TRACX</span> #CheerZone</h1>
      <p class="paragraph text-color-neutral-400 home-hero">View all your submitted photos via your starting number
        back!</p>
      <div class="_2-buttons-wrapper">
        <input type="text" placeholder="Startnumber..." v-model="startNumber">
        <button @click="showPhotosByStartNumber" class="button-primary button-white _2-buttons w-button">Check photos
        </button>
      </div>
      <br>
      <br>
    </div>
    <img v-if="organizationLogoUrl" :src="this.organizationLogoUrl" alt="TRACX Logo" class="organization-logo">
  </section>
</template>

<style lang="scss" scoped>

section.home-hero {
  background-image: url('../../public/images/hero.webp');
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.organization-logo {
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  z-index: 1;
}

.section.home-hero {
  padding-top: 150px;
  padding-bottom: 150px;
  position: relative;
}

.section .button-primary {
  text-decoration: none;
  background-color: #FF6600;
  color: white;
  border-radius: 4px;
  font-weight: 500;
  letter-spacing: 1.2px;
  padding: 12px 24px;
  transition: .2s;
  border: none;
  cursor: pointer;
}

.section .button-primary:hover {
  background-color: #e15800;
}

.section.home-hero-info {
  margin-top: -157px;
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
}

.section h1 {
  color: #FFF;
  text-align: center;
  text-shadow: 17.5px 17.5px 52.5px rgba(0, 0, 0, 0.25);
  font-family: canada-type-gibson, sans-serif;
  font-size: 72px;
  font-weight: 600;
  line-height: 90px;
  letter-spacing: -1.44px;
  margin-bottom: 8px;
}

.section p {
  margin-top: 0;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 40px;
  color: white;
}

.section .home-hero-logo {
  margin-top: -157px;
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
}

.container-medium-963px {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
}

.container-medium-963px.home-hero {
  z-index: 1;
  text-align: center;
  position: relative;
}

.bg {
  position: absolute;
}

.bg.home-hero-filter {
  opacity: .41;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.bg.home-hero-info {
  width: 50%;
  background-color: var(--neutral-800);
  top: 0;
  bottom: 0;
  left: 0;
  right: auto;
}

.bg.home-hero {
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.bg.home-about {
  min-height: 33%;
  background-color: var(--neutral-800);
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
}

.bg.cta {
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.home-hero-tournament-logo {
  width: 200px;
  margin-top: 50px;
  position: absolute;
  left: 20px;
}

._2-buttons-wrapper {
  display: flex;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;

  input {
    border: none;
    padding: 12px 24px;
    font-size: 24px;
    border-radius: 4px;
  }
}

@media screen and (max-width: 991px) {

  .section .home-hero-logo {
    height: 90px;
    top: -50px;
  }

  .gfield--type-consent .ginput_container_consent input {
    width: 30px;
    height: 30px;
  }

  .section h1 {
    font-size: 38px;
    line-height: 60px;
    margin-top: -20px;
  }

  .section p {
    font-size: 18px;
    margin-bottom: 24px;
  }

  .text-section h2 {
    font-size: 32px;
    line-height: 40px;
  }

  .text-section p {
    font-size: 18px;
  }

  .footer .left-side img {
    width: 100%;
    height: 120px;
  }

  .footer {
    margin-top: 120px;
  }

  ._2-buttons-wrapper {
    display: flex;
    justify-content: center;

    input, button {
      width: 100%;
      max-width: 400px;
    }

    button {
      font-size: 20px;
    }
  }

  .form-section .container-medium-963px {
    flex-direction: column;
  }

  .form-section .container-medium-963px > div {
    width: 100%;
  }

  .footer .container-medium-963px {
    display: flex;
    flex-direction: column-reverse;
    gap: 24px;
  }

  .footer .right-side {
    width: 100%;
  }

  .footer .left-side {
    align-items: flex-start;
  }

  .footer .left-side img {
    height: 90px;
  }

  .footer .right-side .marathon-banner {
    width: 100%;
    object-fit: contain;
    height: 280px;
    margin-top: -140px;
  }

  .footer .left-side p {
    margin-bottom: 40px;
  }
}

</style>

<script>

export default {
  name: 'InputPage',
  data() {
    return {
      startNumber: null,
      organizationLogoUrl: null,
    }
  },
  mounted() {
    this.getEventData();
  },
  methods: {
    showPhotosByStartNumber() {
      if (!this.startNumber) {
        alert('Fill in the start number');
        return;
      }
      this.$router.push(`/${this.$route.params.eventID}/photos/${this.startNumber}`);
    },
    async getEventData() {
      try {
        const response = await this.$api.app.get(`v1/web/events/${this.$route.params.eventID}?include=organization`);
        this.organizationLogoUrl = response.data.organization.logo + '/logo';
      } catch (error) {
        console.error('Error fetching passings:', error);
      }
    },
  }
}
</script>
