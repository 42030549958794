import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {doc, getDoc, addDoc, collection, getFirestore, query, where, getDocs} from "firebase/firestore";
import { getAuth, signInAnonymously } from "firebase/auth";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"

const firebaseConfig = {
    apiKey: "AIzaSyDoa6rnk5YBsgNP7redUsvbi72rGMFGtPo",
    authDomain: "cheerzone-5eb66.firebaseapp.com",
    projectId: "cheerzone-5eb66",
    storageBucket: "cheerzone-5eb66.firebasestorage.app",
    messagingSenderId: "248225192430",
    appId: "1:248225192430:web:b43f85c4723946fb7196ff",
    measurementId: "G-CML4BHQ1Q7"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db =  getFirestore(app);
const storage = getStorage(app);

await signInAnonymously(auth);

export {auth, db, storage}

//
//
// const docRef = doc(db, 'submissions', 'yGSsMYSzj4Y6PMrEiIqq');
// const docSnap = await getDoc(docRef);
// if (docSnap.exists()) {
//     console.log('Document data:', docSnap.data());
// } else {
//     console.log('No such document!');
// }
//
// if (docSnap.exists()) {
//     console.log('Document data:', docSnap.data());
// } else {
//     console.log('No such document!');
// }


// console.log(auth.currentUser)
// const userData = {
//     email: "test@test.nl",
//     image: "",
//     name: "test",
//     start_number: "123",
//     terms: true,
//     user: auth.currentUser.uid,
//     video: ""
// };
// //
// //
export async function createSubmission(formData) {
    try {
        await addDoc(collection(db, 'submissions'), formData);

        return true;
    } catch (error) {
        console.error("Error creating document: ", error);
        return false;
    }
}

// createSubmission()

// async function getSubmissionByStartNumber(startNumber) {
//     try {
//         // Create a query against the 'submissions' collection
//         const q = query(collection(db, "submissions"), where("start_number", "==", startNumber));
//
//         // Execute the query
//         const querySnapshot = await getDocs(q);
//
//         if (querySnapshot.empty) {
//             console.log("No matching documents found!");
//         } else {
//             querySnapshot.forEach((doc) => {
//                 console.log(`Document ID: ${doc.id}, Data:`, doc.data());
//             });
//         }
//     } catch (error) {
//         console.error("Error retrieving document: ", error);
//     }
// }
//
// // Call the function with the specific start number
// getSubmissionByStartNumber("123");


