import {createRouter, createWebHistory} from 'vue-router';
import inputPageVideos from '@/views/input-page-video';
import inputPagePhotos from "@/views/input-page-photo";
import videosPage from "@/views/videos-page";
import photosPage from "@/views/photos-page";
import homePage from "@/views/home-page";
import formPage from "@/views/midwinter-form-page";

const routes = [
  {
    path: '/:eventID',
    component: inputPagePhotos,
    meta: {
      title: 'Cheerzone - TRACX'
    }
  },
  {
    path: '/1138',
    redirect: '/1138/videos',
  },
  {
    path: '/:eventID/videos',
    component: inputPageVideos,
    meta: {
      title: 'Cheerzone - TRACX'
    }
  },
  {
    path: '/:eventID/photos',
    component: inputPagePhotos,
    meta: {
      title: 'Cheerzone - TRACX'
    }
  },
  {
    path: '/:eventID/videos/:startNumber',
    component: videosPage,
    meta: {
      title: 'Cheerzone video\'s - TRACX'
    }
  },
  {
    path: '/:eventID/photos/:startNumber',
    component: photosPage,
    meta: {
      title: 'Cheerzone photos - TRACX'
    }
  },
  {
    path: '/midwinter',
    redirect: '/midwinter/upload'
  },
  {
    path: '/midwinter/upload',
    component: formPage,
    meta: {
      title: 'Cheerzone - Midwinter upload'
    }
  },
  {
    path: '/',
    redirect: '/midwinter/upload'
  }
];

const router = new createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})

export default router;
