<template>
  <section class="section home-hero">
    <div class="container-medium-963px home-hero">
      <br>
      <a href="https://tracx.events/" target="_blank">
        <img src="/images/logos/tracx-logo-white.svg" alt="TRACX Logo" class="home-hero-logo">
      </a>
      <h1 class="title text-color-neutral-100 home-hero"><span class="font-italic">TRACX</span>
        <span v-if="this.$route.params.eventID === '1138'" class="subtitle"><br>4Daagse aanmoedigingsvideo</span>
        <span v-else> #CheerZone</span>
      </h1>
      <p class="paragraph text-color-neutral-400 home-hero">
        <span v-if="this.$route.params.eventID === '1138'">Bekijk jouw persoonlijke videoboodschap(pen) voor extra support op je laatste wandeldag!</span>
        <span v-else>Bekijk via je startnummer al je ingezonden video's terug!</span>
      </p>
      <div class="_2-buttons-wrapper">
        <input type="text" :placeholder="this.$route.params.eventID === '1138' ? 'Registratienummer' : 'Startnummer...'" v-model="startNumber">
        <button @click="showVideosByStartNumber" class="button-primary button-white _2-buttons w-button">
          Bekijk video's
        </button>
      </div>
      <div class="organization-logo-wrapper">
        <img v-if="organizationLogoUrl" :src="this.organizationLogoUrl" alt="TRACX Logo" class="organization-logo">
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'InputPage',
  data() {
    return {
      startNumber: null,
      organizationLogoUrl: null,
    }
  },
  mounted() {
    this.getEventData();
  },
  methods: {
    showVideosByStartNumber() {
      if (!this.startNumber) {
        alert('Vul het startnummer in');
        return;
      }
      this.$router.push(`/${this.$route.params.eventID}/videos/${this.startNumber}`);
    },
    async getEventData() {
      try {
        const response = await this.$api.app.get(`v1/web/events/${this.$route.params.eventID}?include=organization`);
        this.organizationLogoUrl = response.data.organization.logo + '/logo';
      } catch (error) {
        console.error('Error fetching passings:', error);
      }
    },
  }
}
</script>

<style lang="scss" scoped>

section.home-hero {
  background-image: url('../../public/images/hero.webp');
  background-size: cover;
  background-position: center;
}

.organization-logo-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.organization-logo {
  width: 200px;
}

.section.home-hero {
  padding-top: 150px;
  padding-bottom: 150px;
  position: relative;
  overflow: hidden;
}

.section .button-primary {
  text-decoration: none;
  background-color: #FF6600;
  color: white;
  border-radius: 4px;
  font-weight: 500;
  letter-spacing: 1.2px;
  padding: 12px 24px;
  transition: .2s;
  border: none;
  cursor: pointer;
}

.section .button-primary:hover {
  background-color: #e15800;
}

.section.home-hero-info {
  margin-top: -157px;
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  overflow: hidden;
}

.section h1 {
  color: #FFF;
  text-align: center;
  text-shadow: 17.5px 17.5px 52.5px rgba(0, 0, 0, 0.25);
  font-family: canada-type-gibson, sans-serif;
  font-size: 72px;
  font-weight: 600;
  line-height: 90px;
  letter-spacing: -1.44px;
  margin-bottom: 8px;
}

.section .subtitle {
  font-size: 54px;
  margin-bottom: 60px;
  margin-top: -50px;
  display: block;
  line-height: 60px;
}

@media screen and (min-width: 993px) {
  .section .subtitle {
    margin-bottom: 60px;
  }
}

.section p {
  margin-top: 0;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 40px;
  color: white;
}

.section .home-hero-logo {
  margin-top: -157px;
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  overflow: hidden;
}

.container-medium-963px {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
}

.container-medium-963px.home-hero {
  z-index: 1;
  text-align: center;
  position: relative;
}

.bg {
  position: absolute;
}

.bg.home-hero-filter {
  opacity: .41;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.bg.home-hero-info {
  width: 50%;
  background-color: var(--neutral-800);
  top: 0;
  bottom: 0;
  left: 0;
  right: auto;
}

.bg.home-hero {
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.bg.home-about {
  min-height: 33%;
  background-color: var(--neutral-800);
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
}

.bg.cta {
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.home-hero-tournament-logo {
  width: 200px;
  margin-top: 50px;
  position: absolute;
  left: 20px;
}

._2-buttons-wrapper {
  display: flex;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;

  input {
    border: none;
    padding: 12px 24px;
    font-size: 24px;
    border-radius: 4px;
  }
}

@media screen and (max-width: 991px) {

  .section .home-hero-logo {
    height: 90px;
    top: -50px;
  }

  .gfield--type-consent .ginput_container_consent input {
    width: 30px;
    height: 30px;
  }

  .section h1 {
    font-size: 38px;
    line-height: 60px;
    margin-top: -20px;
  }

  .section p {
    font-size: 18px;
    margin-bottom: 24px;
  }

  .text-section h2 {
    font-size: 32px;
    line-height: 40px;
  }

  .text-section p {
    font-size: 18px;
  }

  .footer .left-side img {
    width: 100%;
    height: 120px;
  }

  .footer {
    margin-top: 120px;
  }

  ._2-buttons-wrapper {
    display: flex;
    justify-content: center;

    input, button {
      width: 100%;
      max-width: 400px;
    }

    button {
      font-size: 20px;
    }
  }

  .form-section .container-medium-963px {
    flex-direction: column;
  }

  .form-section .container-medium-963px > div {
    width: 100%;
  }

  .footer .container-medium-963px {
    display: flex;
    flex-direction: column-reverse;
    gap: 24px;
  }

  .footer .right-side {
    width: 100%;
  }

  .footer .left-side {
    align-items: flex-start;
  }

  .footer .left-side img {
    height: 90px;
  }

  .footer .right-side .marathon-banner {
    width: 100%;
    object-fit: contain;
    height: 280px;
    margin-top: -140px;
  }

  .footer .left-side p {
    margin-bottom: 40px;
  }
}

</style>
